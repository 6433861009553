import React, { useEffect, useRef, useState } from "react";
import { getCities, getCountry, getCountryStates } from "services/allServices";

export function useToggle(defaultValue) {
  const [data, setData] = useState(defaultValue);

  function toggle() {
    setData(!data);
  }

  return [data, toggle];
}


export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useToggleHeader() {

  const [domainOpen, setDomainOpen] = useState(false);
  const [webHostingOpen, setWebHostingOpen] = useState(false);
  const [sunucularOpen, setSunucularOpen] = useState(false);
  const [ePostaOpen, setEPostaOpen] = useState(false);
  const [ekstraOpen, setEkstraOpen] = useState(false);
  const [kurumsalOpen, setKurumsalOpen] = useState(false);

  function toggle(value, mobile) {
    closeToggles();
    if (value === "1") {
      if (mobile) {
        setDomainOpen(!domainOpen);
      } else {
        setDomainOpen(true);
      }
    }
    if (value === "2") {
      if (mobile) {
        setWebHostingOpen(!webHostingOpen);
      } else {
        setWebHostingOpen(true);
      }
    }
    if (value === "3") {
      if (mobile) {
        setSunucularOpen(!sunucularOpen);
      } else {
        setSunucularOpen(true);
      }
    }
    if (value === "4") {
      if (mobile) {
        setEPostaOpen(!ePostaOpen);
      } else {
        setEPostaOpen(true);
      }
    }
    if (value === "5") {
      if (mobile) {
        setEkstraOpen(!ekstraOpen);
      } else {
        setEkstraOpen(true);
      }
    }
    if (value === "6") {
      if (mobile) {
        setKurumsalOpen(!kurumsalOpen);
      } else {
        setKurumsalOpen(true);
      }
    }

  }

  function closeToggles() {
    setDomainOpen(false);
    setWebHostingOpen(false);
    setSunucularOpen(false);
    setEPostaOpen(false);
    setEkstraOpen(false);
    setKurumsalOpen(false);
  }

  return [domainOpen, webHostingOpen, sunucularOpen, ePostaOpen, ekstraOpen, kurumsalOpen, toggle, closeToggles];
}


export function useCountry() {
  const [country, setCountry] = useState();
  const [countryStates, setCountryStates] = useState();
  const [cities, setCities] = useState();

  const [countryNames, setCountryNames] = useState();
  const [countryStatesNames, setCountryStatesNames] = useState();
  const [citiesNames, setCityNames] = useState();
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [selectedState, setSelectedState] = useState(0);
  const [selectedCity, setSelectedCity] = useState(0);

  const onGetCountry = async () => {
    let countries = [];
    countries = await getCountry();
    setCountry(countries);
    let countryNamesArr = [];
    countries?.map((item) => {
      countryNamesArr.push(item.country_name);
    });
    setCountryNames(countryNamesArr);
  };
  const getSelectedCountry = (countryName) => {
    country.map((ct) => {
      if (ct.country_name === countryName) {
        setSelectedCountry(ct);
        setCountryStatesNames();
        setCityNames();
        onGetStates(ct.country_code);
      }
    });
  };
  const onGetStates = async (countryCode) => {
    let statesarr = [];
    statesarr = await getCountryStates(countryCode);
    setCountryStates(statesarr);
    let countryStatesNamesArr = [];
    await statesarr?.map((item) => {
      countryStatesNamesArr.push(item.state_name);
    });
    setCountryStatesNames(countryStatesNamesArr);
  };

  const getSelectedState = (stateName) => {
    countryStates.map((st) => {
      if (st.state_name === stateName) {
        setCityNames();
        setSelectedState(st);
        onGetCities(st.state_code);
      }
    });
  };

  const onGetCities = async (stateCode) => {
    let citiessarr = [];
    citiessarr = await getCities(selectedCountry.country_code, stateCode);
    setCities(citiessarr);
    let citiesNamesArr = [];
    await citiessarr?.map((item) => {
      citiesNamesArr.push(item.cities_name);
    });
    setCityNames(citiesNamesArr);
  };
  useEffect(() => {
    onGetCountry();
  }, []);

  return [country, countryStates, cities, countryNames, countryStatesNames, citiesNames, selectedCountry, selectedState, selectedCity, onGetCountry, getSelectedCountry, onGetStates, getSelectedState, onGetCities, setSelectedCity];

}


export function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute("data-status", "loading");
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute("data-status", event.type === "load" ? "ready" : "error");
        };

        script.addEventListener("load", setAttributeFromEvent);
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"));
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };

      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );

  return status;
}

export function useCloseSelectBox(func) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return [wrapperRef];
}

export function useConfigureSelectBoxItem() {
  const [allData, setAllData] = useState([]);

  function configureSelectBoxItem(data, id, text) {
    let configure = [];
    data?.map((item) => {
      configure.push({
        id: item[id],
        text: item[text]
      });
    });
    setAllData(configure);
  }

  return [allData, configureSelectBoxItem];
}