import Head from "next/head";
import React, { useEffect, useState } from "react";

export default function Loading() {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(!fade);
    }, 500);
    return () => clearInterval(interval);
  });

  return (
    <div className="flex items-center justify-center w-full h-screen">
           <Head>
        <title>Makdos</title>
      </Head>
      <img
        src="/LoadingLogo.png"
        id="target"
        className={`${fade ? " transition-all duration-300 opacity-20" : " transition-all duration-300 opacity-80"} `}
        // style={{
        //   left: "40%",
        //   top: "45%",
        //   transition: "0.8s",
        //   width: "500",
        //   height: "200",
        //   position: "absolute",
        //   opacity: fade ? 0.2 : 0.8,
        // }}
        width={300}
        height={120}
        alt="Makdos"
      />
    </div>
  );
}
