export const generatePassword = () => {
  let chars = [
    "ABCDEFGHJKLMNPQRSTUVWXYZ",
    "23456789",
    "abcdefghijkmnopqrstuvwxyz",
    "@#!$:?/|~>()"
  ];
  return [3, 3, 4, 2]
    .map(function (len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join("");
    })
    .concat()
    .join("")
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
};

export const changeCurrency = (currencyRates, currencyType, price, defaultCurrency = "TL") => {
  let newPrice = price;
  if (currencyRates) {
    let defaultRate = currencyRates?.find(x => x.currency_type === defaultCurrency)?.currency_price;
    currencyRates?.map((rate) => {
      if (rate.symbol === currencyType && defaultRate) {
        newPrice = (price * defaultRate) / rate.currency_price;
      }
    });
    return parseFloat(newPrice).toFixed(2);
  }
};

export const findCurrency = (currencyType) => {
  if (currencyType === "₺") {
    return "TL"
  } else if (currencyType === "$") {
    return "USD"
  } else if (currencyType === "€") {
    return "EURO"
  } else if (currencyType === "₿") {
    return "BTC"
  } else if (currencyType === "₽") {
    return "RUBLE"
  }
};




export function isNullOrEmptyOrUndefined(item) {
  return item === null || item === "" || typeof item === "undefined";
}

export const combineIDContent = async (data) => {
  let result = [];
  if (!isNullOrEmptyOrUndefined(data))
    if (typeof data.length !== "undefined")
      await data.map((d) => {
        let newData = { ...d, ...d.content };
        const { content, ...rest } = newData;
        result.push({ ...rest });
      });
    else {
      let newData = { ...data.content, ...data };
      const { content, ...rest } = newData;
      result = { ...rest }
    }
  return result;
};


export const combineIDContentByPrefix = async (data, prefix) => {
  let result = [];
  if (!isNullOrEmptyOrUndefined(data))
    if (typeof data.length !== "undefined")
      await data.map((d) => {
        result.push({
          id: d[prefix].id, ...d[prefix].content,
          group_type: d[prefix].group_type,
          group_group: d[prefix].group_group
        });
      });
    else
      result = {
        ...data[prefix].content,
        id: data[prefix].id,
        group_type: data[prefix].group_type,
        group_group: data[prefix].group_group
      };
  return result;
};

export const combineIDContentBySSL = async (data, prefix) => {
  let result = [];
  if (!isNullOrEmptyOrUndefined(data))
    if (typeof data.length !== "undefined")
      await data.map((d) => {
        result.push({
          id: d[prefix].id, ...d[prefix].content,
          available_components: d[prefix].product_available_component,
          product_type_id: d[prefix].product_type_id,
          product_type_name: d[prefix].product_type_id
        });
      });
    else
      result = {
        ...data[prefix].content,
        id: data[prefix].id,
        available_components: data[prefix].product_available_component,
        product_type_id: data[prefix].product_type_id,
        product_type_name: data[prefix].product_type_id
      };
  return result;
};


export function calculatePaymentInterval(cycle) {
  if (cycle === "one_month" || cycle === "three_month" || cycle === "six_month") {
    return "monthly";
  } else {
    return "yearly";
  }
}

export function calculatePaymentCycle(cycle) {
  if (cycle === "one_month") {
    return 1;
  } else if (cycle === "three_month") {
    return 3;
  } else if (cycle === "six_month") {
    return 6;
  } else if (cycle === "one_year") {
    return 12;
  } else if (cycle === "two_year") {
    return 24;
  } else if (cycle === "three_year") {
    return 36;
  }else {
    return 1;
  }
}

export function sortConfigureItems(items) {
  let groupList = []
  items.map((item) => {
    if (!groupList.find(group => group.group_name === item.component_name.slice(0, 5))) {
      groupList.push({ group_name: item.component_name.slice(0, 5), items: [item] })
    }
    else {
      groupList.find(group => group.group_name === item.component_name.slice(0, 5)).items.push(item)
    }
  })

  let priceSort = groupList.map((item) => {
    item.items.sort((a, b) => {
      if (a.price > b.price) return 1;
      if (a.price < b.price) return -1;
      return 0;
    })
    return item;
  })

  let allList = []
  priceSort.map((item) => {
    allList = [...allList, ...item.items]
  })

  return allList;
}
