import React from "react";

function SimpleTextBox({ labelName, className = "", inputName, placeholder, innerRef, value, onChange }) {
  return (
    <div className="flex flex-col w-full mt-5 lg:mt-0">
      <label className="mb-1 ">{labelName}</label>
      <input ref={innerRef} onChange={onChange && ((e) => onChange(e.target.value))}
        className={`w-full border-2 border-makdosSecondaryGray rounded-md py-2 px-3 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-makdosYellow ${className}`}
        type="text" name={inputName} placeholder={placeholder} value={value && value} />
    </div>
  );
}

export default SimpleTextBox;
