import React, { useEffect, useRef, useState } from "react";

function PhoneSelectBox({ selectedItem, setSelectedItem, data, defaultSelect, labelName, disabled }) {
  const [openOptions, setOpenOptions] = useState(false);
  const [filteredItem, setFilteredItem] = useState(data);
  const [inputValue, setInputValue] = useState("");

  const onSelectedItem = (item) => {
    setSelectedItem(item);
    setInputValue("");
    setFilteredItem(data);
    setOpenOptions(false);

  };

  const filterItems = (e) => {
    let name = e.target.value;
    setInputValue(name);
    let newList = data;
    newList = newList.filter((a) => {
      if (a)
        return a.text.toLowerCase().search(name.toLowerCase()) !== -1;
    });
    setFilteredItem(newList);
  };

  useEffect(() => {
    setFilteredItem(data);
  }, [data]);


  useEffect(() => {
    if (defaultSelect)
      setSelectedItem(defaultSelect);
  }, [defaultSelect]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //when outside of the combobox clicked, it will be closed
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenOptions(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="relative w-32 comboBox lg:w-auto" ref={wrapperRef}>
      <label className="">{labelName}</label>

      <button type="button" onClick={() => setOpenOptions(!openOptions)}
        className="flex justify-between w-full px-3 py-3 mt-1 mr-1 text-sm font-medium bg-white border rounded-md shadow-sm lg:w-auto border-makdosSecondaryGray focus:border-1 focus:border-makdosYellow focus:ring-1 focus:ring-makdosYellow text-textGray hover:bg-gray-50 focus:outline-none ">
        <p className="text-left text-makdosTitleDiv">+{selectedItem?.id}</p>
        <div className="flex">
          <div className="ml-2 bg-makdosSecondaryGray w-0.5 h-5"></div>
          <svg className="w-5 h-5 ml-1 -mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd" />
          </svg>
        </div>

      </button>
      {openOptions && !disabled &&
        <div
          className="absolute z-30 w-full mt-2 overflow-y-auto origin-top-left bg-white shadow-lg max-h-44 customScroll lg:left-0 lg:w-52">
          <input onChange={(e) => filterItems(e)} value={inputValue}
            className="w-full px-4 py-1 focus:outline-none focus:border-none focus:ring-0" type="text"
            placeholder="Ara ..." autoFocus />

          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {filteredItem?.map((item, i) => (
              <div
                key={i}
                onClick={() => onSelectedItem(item)}
                className="flex w-full px-4 py-3 text-sm font-medium bg-white cursor-pointer hover:bg-secondBg focus:outline-none focus:ring-2 "
                id="options-menu" aria-haspopup="true" aria-expanded="true">
                <p className="text-left text-cardText">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );


}

export default PhoneSelectBox;
