import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

function NewSiteModal({ setOpenModal }) {
    const router = useRouter();
    return (
        <div className="fixed inset-0 z-40 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0 ">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    style={{ overflow: "initial" }}
                >
                    <div className="bg-white rounded-md">
                        <div className="flex items-center justify-end px-1 py-1">
                            <i onClick={() => { setOpenModal(false) }} className="text-2xl cursor-pointer ri-close-line text-passive"></i>
                        </div>

                        {router.locale === "tr" ?
                            <Image src={"/newSite.png"} priority width={1140} height={1100} alt="Yeni Site Bilgilendirme" />
                            :
                            <Image src={"/newSiteEN.png"} priority width={1140} height={1100} alt="New Site Information" />
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewSiteModal;
