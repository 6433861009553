import { useTranslation } from "next-i18next";
import { useGlobalStore } from "store/GlobalStore";

const CookiePopup = ({ setShowCookiePopup }) => {
    const { t } = useTranslation(["common"]);
    const [cookieToAccept, setCookieToAccept] = useGlobalStore((state) => [state.cookieToAccept, state.setCookieToAccept]);

    return (
        <div className="fixed flex flex-col space-y-3 left-0 lg:left-5 bottom-5 lg:bottom-2 z-[999999] items-end ">
            <div className="w-full lg:w-[493px] flex flex-col space-y-4 bg-white rounded-md p-4 shadow-lg">
                <div className="flex items-center justify-between w-full">
                    <div>
                        <p className="text-lg font-bold">{t('cookieToAcceptTitle')}</p>
                    </div>
                    <div className="cursor-pointer" onClick={() => setShowCookiePopup(false)}>
                        <i className="text-xl ri-close-line" />
                    </div>
                </div>

                <div className="flex items-center justify-center space-x-4 text-sm font-normal">
                    <p className="">{t('cookieToAcceptParagraph')}</p>
                </div>

                <div className="flex justify-center space-x-4" onClick={() => {

                }}>
                    <button className="w-full py-2 text-center duration-300 border rounded-md cursor-pointer bg-buttonBg border-slate-200 hover:opacity-75" onClick={() => setShowCookiePopup(false)} type="button">
                        {t('later')}
                    </button>
                    <button className="flex items-center justify-center w-full py-2 space-x-1 duration-300 rounded-md cursor-pointer bg-makdosYellow hover:bg-makdosLaci hover:text-textWhite" onClick={() => setCookieToAccept(true)} type="button">
                        <p>{t('toAccept')}</p>
                        <i className="ri-checkbox-circle-fill" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CookiePopup