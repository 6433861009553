import { useCallback, useEffect, useRef, useState } from "react";
import LiveChatStatus from "./LiveChatStatus";
import StartChat from "./StartChat";
import * as ls from "local-storage";
import { checkVisitorInfo, closeWebSocket } from "services/allServices";
import { useGlobalStore } from "store/GlobalStore";
import fernet from "fernet";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "hooks";


function LiveChat() {
  const { t } = useTranslation(["common", "nav"]);

  let webSocket = "";
  let webSocketCheck = ""

  const [openChat, setOpenChat] = useState(false);
  const liveChatScreenRef = useRef(null);
  const windowSize = useWindowSize();
  const [height, setHeight] = useState(0);
  const [positions, setPositions] = useState(20);
  const [isOverflown, setIsOverflown] = useState(false);
  const [chatStatus, setChatStatus] = useState();
  const [messages, setMessages] = useState([]);
  const [webSocketData, setWebSocketData] = useState("");
  const [webSocketState, setWebSocketState] = useState("");
  const [step, setStep] = useState(0);
  const [openFullScreen, setOpenFullScreen] = useState(false);


  const handler = useCallback((mouseDownEvent) => {
    const startSize = height;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      let totalHeight = window.innerHeight;
      let componentHeight = document.getElementById("live-chat-makdos").offsetHeight;
      if (componentHeight > 300 && (componentHeight - 60) < totalHeight) {
        setHeight(startSize + (startPosition.y - mouseMoveEvent.pageY));
      } else {
        document.body.removeEventListener("mousemove", onMouseMove);
      }

    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp);

    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, [height]);

  const handlePosition = useCallback((mouseDownEvent) => {
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent) {
      let onScreenLeft = mouseDownEvent.view.screen.width - (mouseDownEvent.view.screen.width * 30 / 100);
      let onScreenRight = 20;
      if ((positions + startPosition.x - mouseMoveEvent.pageX > onScreenRight) && (positions + startPosition.x - mouseMoveEvent.pageX < onScreenLeft)) {
        setPositions((positions + startPosition.x - mouseMoveEvent.pageX));
      }
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });

    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, [positions, isOverflown]);

  const openWebSocket = () => {
    if (!webSocket) {
      webSocket = new WebSocket(`${process.env.NEXT_PUBLIC_CHAT_WS_URL}/chat/connect?visitor_token=${ls.get("l_c_i_d")}`);
      //webSocket = new WebSocket(`ws://192.168.1.201:35000/chat/connect?visitor_token=${ls.get("l_c_i_d")}`);
      webSocket.onopen = () => {
        setWebSocketState("open");
      };
      webSocket.onmessage = (e) => {
        setWebSocketState("open");
        if (JSON.parse(e.data)?.status === "service_stoped") {
          setOpenChat(!openChat);
          closeWebSocket(ls.get("l_c_i_d"));
          ls.set("l_c_i_d", "");
          setWebSocketState("close");
          if (webSocket) {
            webSocket.onclose = () => {
            };
            webSocket.close();
          }
        }
        else {
          setWebSocketData(JSON.parse(e.data));
        }


      };
      webSocket.onclose = () => {
        setWebSocketState("close");
        setTimeout(() => {
          openWebSocket()
        }, 5000)
      };
      webSocket.onerror = () => {
        setWebSocketState("error")
        setTimeout(() => {
          openWebSocket()
        }, 5000)
      };
    }

  };
  const openWebSocketCheck = () => {
    if (!webSocketCheck) {
      webSocketCheck = new WebSocket(`${process.env.NEXT_PUBLIC_CHAT_WS_URL}/check/connect`);
      //webSocketCheck = new WebSocket(`ws://192.168.1.201:35000/check/connect`);
      webSocketCheck.onopen = () => {
        setChatStatus("open")
      };
      webSocketCheck.onmessage = (e) => {
        if (JSON.parse(e.data)?.status === "service_started") {
          setChatStatus("open")
        }
        else {
          setChatStatus("close")
          setTimeout(() => {
            openWebSocketCheck()
          }, 5000)
        }
      };
      webSocketCheck.onclose = () => {
        setChatStatus("close");
        setTimeout(() => {
          openWebSocketCheck()
        }, 5000)
      };
      webSocketCheck.onerror = () => {
        setChatStatus("close");
        setTimeout(() => {
          openWebSocketCheck()
        }, 5000)
      };
    }

  };

  useEffect(() => {
    if (webSocketData) {
      if (webSocketData.status === "chat_closed") {
        ls.remove("l_c_i_d");
        ls.remove("l_c_c_i_d");
      } else {
        setMessages(webSocketData.message_list);
        let audio = new Audio("/newChat.wav");
        if (!webSocketData?.message_list[webSocketData.message_list?.length - 1]?.is_visitor) {
          audio.play();
        }
      }
    }
  }, [webSocketData]);

  useEffect(() => {
    if (ls.get("l_c_c_i_d") && ls.get("l_c_i_d")) {
      var secret = new fernet.Secret("YmVya2ViZXJrZWJlcmtlYmVya2ViZXJrZWJlcmtlYmU=");
      var token = "";
      token = new fernet.Token({
        secret: secret,
        token: ls.get("l_c_i_d"),
        ttl: 0
      });
      let tokenData = token.decode();
      let tokenDataArray = tokenData.split("_|_");
      let configure = {
        content: {
          email: tokenDataArray[2],
          visitor_token: localStorage.getItem("l_c_i_d") || ""
        }
      };
      checkVisitorInfo(configure).then(async (res) => {
        if (res.last_chat_status === "closed") {
          ls.remove("l_c_i_d");
          ls.remove("l_c_c_i_d");
          await setOpenChat(true);
          await setOpenChat(false);
        } else {
          setOpenChat(true);
          openWebSocket();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (openChat && !webSocketCheck) {
      openWebSocketCheck()
    }
  }, [openChat])

  useEffect(() => {
    setInterval(() => {
      if (chatStatus === "close") {
        openWebSocketCheck()
      }
    }, 5000)

    return () => {
      clearInterval()
    }
  }, [chatStatus])

  useEffect(() => {
    setInterval(() => {
      if (webSocketState === "close") {
        openWebSocket()
      }
    }, 5000)

    return () => {
      clearInterval()
    }
  }, [webSocketState])


  return (
    <div id="live-chat-makdos" via="utku&berke" className="fixed flex flex-col space-y-3 bottom-5 lg:bottom-2 z-[999999] items-end" style={{ right: windowSize.width < 720 ? "0px" : `${positions}px` }}>
      <div ref={liveChatScreenRef} id="live-chat-screen"
        className={`${openChat ? `translate-y-0` : "h-2 translate-y-96 opacity-0"} flex lg:w-[400px] w-full ${isOverflown === "left" ? "justify-start" : "justify-end"} duration-500 flex-col rounded-lg shadow-xl transition-all  `}
      >
        {
          openChat ?
            <div className="relative customScroll">
              <button className="absolute top-0 w-full h-2 cursor-row-resize" onMouseDown={handler}></button>
              {/* <button className="absolute top-0 left-0 w-2 h-full cursor-col-resize" onMouseDown={handlerWidth}></button> */}
              <div
                className="flex items-center justify-between px-6 py-4 border-b border-black border-opacity-25 rounded-t-lg bg-makdosYellow">
                <p className="text-[#0F172A] font-semibold text-2xl">{t('welcome')} 👋</p>
                <div className="flex items-center space-x-2">
                <i onClick={() => setOpenChat(!openChat)} className="cursor-pointer ri-subtract-fill ext-3xl" />

                  <i onClick={() => setOpenFullScreen(!openFullScreen)} className="block text-2xl cursor-pointer ri-fullscreen-line lg:hidden" />



                  <button className="hidden lg:block" type="button" onMouseDown={handlePosition}>
                    <i className="text-2xl opacity-25 cursor-move ri-drag-move-fill" />
                  </button>
                  {/* {liveChatNotification ?
                    <i className="text-2xl opacity-25 cursor-pointer ri-notification-4-line"
                      onClick={() => setLiveChatNotification(false)} />
                    :
                    <i className="text-2xl opacity-25 cursor-pointer ri-notification-off-line"
                      onClick={() => setLiveChatNotification(true)} />
                  } */}


                  <i className="text-3xl cursor-pointer ri-close-line" onClick={() => {
                    setOpenChat(!openChat);
                    closeWebSocket(ls.get("l_c_i_d"));
                    ls.remove("l_c_i_d");
                    setMessages([]);
                    if (webSocket) {
                      webSocket.onclose = () => {
                      };
                      webSocket.close();
                    }
                  }} />
                </div>

              </div>
              <LiveChatStatus status={chatStatus === "close" ? "error" : "online"} />
              {chatStatus === "open" ?
                <StartChat height={height} openChat={openChat} data={messages} openWebSocket={openWebSocket}
                  socketState={webSocketState} upperStep={step} setUpperStep={setStep} deviceWidth={windowSize.width} deviceHeight={windowSize.height} openFullScreen={openFullScreen} />
                :
                <div className="bg-white flex flex-col items-center justify-center rounded-b-md h-[200px]">
                  <p className="font-semibold text-textGray">Şimdilik Canlı Destek hizmeti veremiyoruz.</p>
                  <p className="my-2 text-textGray">
                    <a className="mr-1 font-semibold" href="tel:+908503077744" target="_blank">0850 307 77 44</a>
                    numarasından bize 7/24 ulaşabilirsiniz.</p>
                  <p className="text-textGray">Lütfen daha sonra tekrar deneyin.</p>

                </div>
              }

            </div>
            :
            <></>
        }

      </div>
      <button type="button" id="live-chat-makdos-button"
        className={`${isOverflown === "left" ? "justify-start" : "justify-end"} flex w-full cursor-pointer`}
        onClick={() => setOpenChat(!openChat)}>
        <div
          className="flex items-center justify-center px-2 py-2 space-x-2 rounded-full w-[170px] bg-makdosYellow shadow-xl hover:bg-slate-800 duration-200 text-[#0F172A] hover:text-makdosYellow">
          <p className="font-medium ">{t('liveChat')}</p>
          <i className="text-2xl font-medium ri-message-3-line" />
        </div>
      </button>
    </div>
  );
}

export default LiveChat;