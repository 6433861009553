import { useTranslation } from "next-i18next";

function LiveChatStatus({ status }) {
  const { t } = useTranslation(["common", "nav"]);


  return (
    <div className="flex items-center justify-between px-6 py-4 bg-makdosYellow">
      <p className="text-[#475569]">{t('liveChatCanQuestion')}</p>
      <div>
        {status === "online" ? (
            <div className="flex items-center px-2 py-1 space-x-2 rounded-full bg-greenAlertBg">
              <div className="w-3 h-3 rounded-full bg-alertGreen" />
              <p className="text-alertGreen">{t("active")}</p>
            </div>

          ) :
          status === "busy" ? (
            <div className="flex items-center px-2 py-1 space-x-2 rounded-full bg-redAlertBg">
              <div className="w-3 h-3 rounded-full bg-discountRed" />
              <p className="text-discountRed">{t("busy")}</p>
            </div>
          ) : (
            <div className="flex items-center px-2 py-1 space-x-2 rounded-full bg-slate-200">
              <div className="w-3 h-3 rounded-full bg-slate-500" />
              <p className="text-slate-500">{t("offline")}</p>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default LiveChatStatus;