import create from "zustand";
import { devtools } from "zustand/middleware";
import * as ls from "local-storage";
import { AES, enc } from "crypto-js";


let decryptMessage = (code) => {
  let secretKey = process.env.NEXT_PUBLIC_CRYPTO_KEY;
  if (localStorage.getItem("__u_per")) {
    try {
      let decryptedData = AES.decrypt(code, secretKey).toString(enc.Utf8);
      return (decryptedData);
    } catch (err) {
      // window.location.href = "/";
    }
  }

};

export const useGlobalStore = create(
  devtools((set, get) => ({

    currencyType: ls.get("__currencyType") ? ls.get("__currencyType") : "₺",
    setCurrencyType: (value) => {
      set((state) => ({
        currencyType: value
      }));
      get().addToLocalStorageGlobal();
    },
    currencyRates: ls.get("__currencyRates") || [],
    setCurrencyRates: (value) => {
      set((state) => ({
        currencyRates: value
      }));
      get().addToLocalStorageGlobal();
    },

    permissions: (ls.get("__u_per") && ls.get("__u_per") !== "") ? JSON.parse(decryptMessage(ls.get("__u_per"))) : [],
    setPermissions: (value) => {
      set((state) => ({
        permissions: (typeof value !== "undefined") ? value : []
      }));
    },

    isResponsible: ls.get("__u_is_resp") ? ls.get("__u_is_resp") : false,
    setIsResponsible: (value) => {
      set((state) => ({
        isResponsible: (typeof value !== "undefined") ? value : ""
      }));
    },

    liveChatNotification: ls.get("__l_v_c") || "true",
    setLiveChatNotification: (value) => {
      set((state) => ({
        liveChatNotification: value
      }));
      get().addToLocalStorageGlobal();
    },

    cookieToAccept: ls.get("cookieToAccept") ? ls.get("cookieToAccept") : false,
    setCookieToAccept: (value) => {
      set((state) => ({
        cookieToAccept: value
      }));
      get().addToLocalStorageGlobal();
    },

    addToLocalStorageGlobal: () => {
      localStorage.setItem("__currencyType", JSON.stringify(get().currencyType));
      localStorage.setItem("__currencyRates", JSON.stringify(get().currencyRates));
      localStorage.setItem("__l_v_c", JSON.stringify(get().liveChatNotification));
      localStorage.setItem("cookieToAccept", JSON.stringify(get().cookieToAccept));
    },
    checkLocalStorageGlobal: () => {
      if (typeof localStorage.getItem("__currencyType") !== "undefined" && localStorage.getItem("__currencyType") !== null && localStorage.getItem("__currencyType") !== "")
        set((state) => ({ currencyType: JSON.parse(localStorage.getItem("__currencyType")) }));
      if (typeof localStorage.getItem("__currencyRates") !== "undefined" && localStorage.getItem("__currencyRates") !== null && localStorage.getItem("__currencyRates") !== "")
        set((state) => ({ currencyRates: JSON.parse(localStorage.getItem("__currencyRates")) }));
      if (typeof localStorage.getItem("__l_v_c") !== "undefined" && localStorage.getItem("__l_v_c") !== null && localStorage.getItem("__l_v_c") !== "")
        set((state) => ({ liveChatNotification: JSON.parse(localStorage.getItem("__l_v_c")) }));
      if (typeof localStorage.getItem("cookieToAccept") !== "undefined" && localStorage.getItem("cookieToAccept") !== null && localStorage.getItem("cookieToAccept") !== "")
        set((state) => ({ cookieToAccept: JSON.parse(localStorage.getItem("cookieToAccept")) }));
    }
  }))
);
