import toast from "react-hot-toast";

/**
 * Tosts componenti
 * @param {string} title - Başlık.
 * @param {string} text - Açıklama yazısı
 * @param {string} position - Toastun çıkacağı yeri belirlemek için kullanılır.
 */
export async function successToast(title, text, position) {
  toast.custom(
    <div className="flex flex-col space-y-2 bg-white rounded-md w-[300px] h-auto shadow-md py-3 px-3">
      <div className="flex justify-between items-center">
        <div className="flex space-x-1 items-center">
          <div className="rounded-md bg-greenAlertBg flex px-2 py-1">
            <i className="ri-checkbox-circle-fill text-alertGreen text-2xl" />
          </div>
          <p className="text-lg font-semibold text-alertGreen">{title ? title : "Success"}</p>
        </div>
        <div>
          <i className="ri-close-line text-2xl cursor-pointer text-textGray" onClick={() => toast.remove()} />
        </div>
      </div>
      <p className="text-cardText text-sm font-medium">{text}</p>
    </div>,
    { position: position ? position : "top-center" }
  );
  return successToast;
}

export async function alertToast(title, text, position) {
  toast.custom(
    <div className="flex flex-col space-y-2 bg-white rounded-md w-[300px] h-auto shadow-md py-3 px-3">
      <div className="flex justify-between items-center">
        <div className="flex space-x-1 items-center">
          <div className="rounded-md bg-redAlertBg flex px-2 py-1">
            <i className="ri-error-warning-fill text-discountRed text-2xl" />
          </div>
          <p className="text-lg font-semibold text-discountRed">{title ? title : "Error"}</p>
        </div>
        <div>
          <i className="ri-close-line text-2xl cursor-pointer text-textGray" onClick={() => toast.remove()} />
        </div>
      </div>
      <p className="text-cardText text-sm font-medium">{text}</p>
    </div>,
    { position: position ? position : "top-center" }
  );
  return alertToast;
}

export async function attentionToast(title, text, position) {
  toast.custom(
    <div className="flex flex-col space-y-2 bg-white rounded-md w-[300px] h-auto shadow-md py-3 px-3">
      <div className="flex justify-between items-center">
        <div className="flex space-x-1 items-center">
          <div className="rounded-md bg-yellowAlertBg flex px-2 py-1">
            <i className="ri-alert-fill text-alertYellow text-2xl" />
          </div>
          <p className="text-lg font-semibold text-alertYellow">{title ? title : "Attention"}</p>
        </div>
        <div>
          <i className="ri-close-line text-2xl cursor-pointer text-textGray" onClick={() => toast.remove()} />
        </div>
      </div>
      <p className="text-cardText text-sm font-medium">{text}</p>
    </div>,
    { position: position ? position : "top-center" }
  );
  return attentionToast;
}

export async function informationToast(title, text, position) {
  toast.custom(
    <div className="flex flex-col space-y-2 bg-white rounded-md w-[300px] h-auto shadow-md py-3 px-3">
      <div className="flex justify-between items-center">
        <div className="flex space-x-1 items-center">
          <div className="rounded-md bg-blueAlertBg flex px-2 py-1">
            <i className="ri-information-fill text-alertBlue text-2xl" />
          </div>
          <p className="text-lg font-semibold text-alertBlue">{title ? title : "Information"}</p>
        </div>
        <div>
          <i className="ri-close-line text-2xl cursor-pointer text-textGray" onClick={() => toast.remove()} />
        </div>
      </div>
      <p className="text-cardText text-sm font-medium">{text}</p>
    </div>,
    { position: position ? position : "top-center" }
  );
  return informationToast;
}