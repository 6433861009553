import axios from "axios";
import api from "./api";
import qs from "query-string";
import { combineIDContent, combineIDContentByPrefix, combineIDContentBySSL } from "helpers/helpersFunc";

let frontpageUrl = process.env.NEXT_PUBLIC_FRONTPAGE_URL;

export async function getCountry() {
  const res = await axios.get(`${frontpageUrl}cache/country/list`);
  return await combineIDContent(res?.data);
}

export async function getCustomerCredit() {
  const res = await api.get(`accounting/get/credits`);
  return res.data;
}


export async function getCountryStates(countryCode) {
  const res = await axios.get(
    `${frontpageUrl}cache/state/by/country/list?country_code=${countryCode}`
  );
  return await combineIDContent(res?.data);
}

export async function getCities(countryCode, stateCode) {
  const res = await axios.get(
    `${frontpageUrl}cache/city/by/country/and/state/list?country_code=${countryCode}&state_code=${stateCode}`
  );
  return await combineIDContent(res?.data);
}

export async function getHomepageProducts() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/product/list/all`);
  return res.data;
}

export async function checkIP() {
  const res = await axios.get(`${frontpageUrl}check/ip`);
  return res.data;
}


//makdos blog
export async function listMakdosBlog() {
  const res = await axios.get(`https://api.makdos.blog/last/for/makdos`);
  return res?.data;
}


// export async function getCountry() {
//   const res = await axios.get(`https://api.mailinu.com/api/v1/staff/company/get/country`);
//   return res.data;
// }

// export async function getCountryStates(countryCode) {
//   const res = await axios.get(`https://api.mailinu.com/api/v1/staff/company/get/states?country_code=${countryCode}`);
//   return res.data;
// }

// export async function getCities(countryCode, stateCode) {
//   const res = await axios.get(`https://api.mailinu.com/api/v1/staff/company/get/cities?country_code=${countryCode}&state_code=${stateCode}`);
//   return res.data;
// }


export async function getProducts(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/get?server_product_id=${id}`);
  return combineIDContentByPrefix(res.data, "get_or_list_products");
}

export async function getProductAvailable(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/available/components/get?server_product_id=${id}`);
  const data = await combineIDContent(res.data);
  return data;
}

export async function getDedicatedServers(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/by/groupid/list?group_id=${id}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return combineIDContentByPrefix(res.data, "list_product_by_group_id");
}

export async function getWindowsServers(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/by/groupid/list?group_id=${id}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return await combineIDContentByPrefix(res.data, "list_product_by_group_id");
}

export async function getLinuxServers(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/by/groupid/list?group_id=${id}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return combineIDContentByPrefix(res.data, "list_product_by_group_id");
}

export async function getCloudServers(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/by/groupid/list?group_id=${id}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return await combineIDContentByPrefix(res.data, "list_product_by_group_id");
}

export async function getWebHostings(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/hosting/product/by/groupid/list?group_id=${id}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return await combineIDContentByPrefix(res.data, "list_product_by_group_id");
}

export async function getIYS(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/iys/product/by/groupid/list?group_id=${id}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return combineIDContentByPrefix(res.data, "list_product_by_group_id");
}

export async function getIYSAvailable(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/iys/product/available/components/get?iys_product_id=${id}`);
  return await combineIDContent(res.data);
}

export async function getSSLCertificate() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/ssl/product/list`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return await combineIDContentBySSL(res.data, "list_ssl_product");
}

export async function getDomainList() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/domain/product/list`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return res.data;
}

export async function getDomainWhoisInfo(domain) {
  const res = await axios.get(`${frontpageUrl}cache/domain/whois/info/get?domain_name=${domain}`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return res.data;
}

export async function getMailServerList() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/mail/server/product/list`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return combineIDContentByPrefix(res.data, "get_or_list_products");
}

export async function getCoLocationList() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/co-location/product/list`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return combineIDContentByPrefix(res.data, "get_or_list_products");
}

export async function getControlPanelList() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/control/panel/product/list`);
  if (res.data.status === "product_not_found")
    return [];
  else
    return combineIDContentByPrefix(res.data, "get_or_list_products");
}

export async function getHostingAvailable(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/hosting/product/available/components/get?hosting_product_id=${id}`);
  return await combineIDContent(res.data);
}

export async function getHosting(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/hosting/product/get?hosting_product_id=${id}`);
  return combineIDContentByPrefix(res.data, "get_or_list_products");
}

export async function getMailAvailable(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/mail/server/product/available/components/get?server_product_id=${id}`);
  return await combineIDContent(res.data);
}

export async function getMail(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/mail/server/product/get?server_product_id=${id}`);
  return combineIDContentByPrefix(res.data, "get_or_list_products");
}

export async function getCoLocationAvailable(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/co-location/product/available/components/get?co_location_product_id=${id}`);
  return await combineIDContent(res.data);
}

export async function getCoLocation(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/co-location/product/get?co_location_product_id=${id}`);
  return combineIDContentByPrefix(res.data, "get_or_list_products");
}


export async function getBackup(server_type, backupRequirement) {
  const res = await axios.post(`${frontpageUrl}add/customer/basket/server/component/disk/raid/bup?server_type=${server_type}`, backupRequirement);
  return res.data;
}

// export async function getBackup(server_type, backupRequirement) {
//   const res = await api.post(`http://192.168.1.201:8000/api/v1/product/add/customer/basket/server/component/disk/raid/bup?server_type=${server_type}`, backupRequirement);
//   console.log(res.data)
//   return res.data;
// }

export async function getSubnetInfo(subnet_type) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/subnet_info/get?subnet_type=${subnet_type}`);
  return res.data;
}

export async function getSubnetID(id) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/subnet_id/get?component_id=${id}`);
  return res.data;
}

export async function getIpWithPrice(subnet_id, default_ip, quantity) {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/server/product/waf/ddos/ips/price/get?${subnet_id !== null ? "subnet_id=" + subnet_id + "&" : ""}default_ip=${default_ip}&quantity=${quantity}`);
  return res.data;
}

export async function getDataseAndDBPrice(osAndDb, product_type_id) {
  const res = await axios.post(`${frontpageUrl}server/product/mssql/os/price/get?product_type_id=${product_type_id}`, osAndDb);
  return res.data;
}

export async function getDomainPremiumDNSPrices() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/domain/premium/dns/prices/list`);
  return res.data;
}

export async function getDomainWhoisPrices() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/domain/whois/prices/list`);
  return res.data;
}

//Basket Servers
export async function addProductBasket(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, hostname, nameserver1, nameserver2, configureComponent, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,
    product_note: configurationNote,
    hostname: hostname ? hostname : null,
    nameserver1: nameserver1 ? nameserver1 : null,
    nameserver2: nameserver2 ? nameserver2 : null,
    components: configureComponent,
    currency: currency
  };
  const res = await api.post(`accounting/basket/server/product/add`, { content: configure });
  return res.data;
}

export async function addComponentBasket(component) {
  const res = await api.post(`accounting/basket/server/component/add`, component);
  return res.data;
}


//Basket Hosting
export async function addProductBasketHosting(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, domain, configureComponent, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,
    product_note: configurationNote,
    domain: domain,
    components: configureComponent,
    currency: currency

  };
  const res = await api.post(`accounting/basket/hosting/product/add`, { content: configure });
  return res.data;
}

export async function addComponentBasketHosting(component) {
  const res = await api.post(`accounting/basket/hosting/component/add`, component);
  return res.data;
}

//Basket Mail
export async function addProductBasketMail(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, hostname, nameserver1, nameserver2, configureComponent, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,

    product_note: configurationNote,
    hostname: hostname ? hostname : null,
    nameserver1: nameserver1 ? nameserver1 : null,
    nameserver2: nameserver2 ? nameserver2 : null,
    components: configureComponent,
    currency: currency
  };
  const res = await api.post(`accounting/basket/mail/product/add`, { content: configure });
  return res.data;
}

export async function addComponentBasketMail(component) {
  const res = await api.post(`accounting/basket/mail/component/add`, component);
  return res.data;
}


//Basket SSL
export async function addProductBasketSSL(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, hostname, components, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,

    product_note: configurationNote,
    hostname: hostname,
    // nameserver1: nameserver1,
    // nameserver2: nameserver2,
    components: [components],
    currency: currency
  };
  const res = await api.post(`accounting/basket/ssl/product/add`, { content: configure });
  return res.data;
}


//Basket İYS
export async function addProductBasketIYS(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, hostname, nameserver1, nameserver2, configureComponent, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,

    product_note: configurationNote,
    hostname: hostname ? hostname : null,
    nameserver1: nameserver1 ? nameserver1 : null,
    nameserver2: nameserver2 ? nameserver2 : null,
    components: configureComponent,
    currency: currency
  };
  const res = await api.post(`accounting/basket/iys/product/add`, { content: configure });
  return res.data;
}

// export async function addComponentBasketSSL(component) {
//   const res = await api.post(`accounting/basket/ssl/component/add`, component);
//   return res.data;
// }


//Basket Control Panel
export async function addProductBasketControlPanel(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, hostname, nameserver1, nameserver2, currency) {
  let configure = {
    content: {
      product_id: product_id,
      project_id: project_id,
      quantity: 1,
      payment_cycle: payment_cycle,
      product_note: configurationNote,
      without_tax_total_product_price: without_tax_total_product_price,
      hostname: hostname,
      nameserver1: nameserver1,
      nameserver2: nameserver2,
      currency: currency
    }
  };
  const res = await api.post(`accounting/basket/control-panel/product/add`, configure);
  return res.data;
}

//Basket Co-Location
export async function addProductBasketCoLocation(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, hostname, nameserver1, nameserver2, configureComponent, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,

    product_note: configurationNote,
    hostname: hostname ? hostname : null,
    nameserver1: nameserver1 ? nameserver1 : null,
    nameserver2: nameserver2 ? nameserver2 : null,
    components: configureComponent,
    currency: currency

  };
  const res = await api.post(`accounting/basket/co-location/product/add`, { content: configure });
  return res.data;
}

export async function addComponentBasketCoLocation(component) {
  const res = await api.post(`accounting/basket/co-location/component/add`, component);
  return res.data;
}


//Basket Domain
export async function addProductBasketDomain(product_id, payment_cycle, project_id, without_tax_total_product_price, configurationNote, premium_dns_available, whois_available, domain_name, domain_status, transfer_code, currency) {
  let configure = {
    product_id: product_id,
    project_id: project_id,
    quantity: 1,
    payment_cycle: payment_cycle,
    without_tax_total_product_price: without_tax_total_product_price,

    product_note: configurationNote,
    premium_dns_available: premium_dns_available,
    whois_available: whois_available,
    domain: domain_name,
    domain_status: domain_status,
    transfer_code: transfer_code,
    currency: currency
  };
  const res = await api.post(`accounting/basket/domain/product/add`, { content: configure });
  return res.data;
}


// export async function addComponentBasket(component) {
//   const res = await api.post(`http://192.168.1.201:8000/api/v1/customer/accounting/add/basket/server/component`, component);
//   console.log(res.data)
//   return res.data;
// }
export async function deleteComponentBasket(id) {
  const res = await api.delete(`accounting/basket/server/component/delete?basket_component_id=${id}`);
  return res.data;
}

export async function getBasket(currencyType, method_type = "credit_card") {
  let currency = "";
  if (currencyType === "₺") {
    currency = "TL";
  } else if (currencyType === "$") {
    currency = "USD";
  } else if (currencyType === "€") {
    currency = "EURO";

  } else {
    currency = "TL";

  }
  const res = await api.get(`accounting/basket/get?currencycode=${currency}`);
  return res.data;
}

export async function getCustomerOrderDetails(params) {
  const res = await axios.post(`${frontpageUrl}get/customer/order`, { content: params });
  const data = res.data;
  return data;
}


export async function getBasketCount() {
  const res = await api.get(`accounting/get/basket/count`);
  return res.data;
}

export async function deleteBasketProduct(basket_product_id, server_product_id, type) {
  const res = await api.delete(`accounting/basket/${type}/product/delete?basket_product_id=${basket_product_id}&${type.replaceAll("-", "_")}_product_id=${server_product_id}`);
  return res.data;
}


export async function updateBasketPaymentCycle(basket_product_id, basket_id, server_product_id, new_payment_cycle, product_type, currencyType) {
  let currency = "";
  if (currencyType === "₺") {
    currency = "TL";
  } else if (currencyType === "$") {
    currency = "USD";
  } else if (currencyType === "€") {
    currency = "EURO";

  } else {
    currency = "TL";

  }
  let configure = {
    basket_product_id: basket_product_id,
    basket_id: basket_id,
    product_id: server_product_id,
    new_payment_cycle: new_payment_cycle,
    currency: currency
  };
  const res = await api.put(`accounting/basket/${product_type}/product/update/payment/cycle`, configure);
  return res.data;
}

export async function updateBasketProject(basket_id, basket_product_id, server_product_id, new_project_id, product_type) {
  let configure = {
    basket_id: basket_id,
    basket_product_id: basket_product_id,
    product_id: server_product_id,
    new_project_id: new_project_id
  };
  const res = await api.put(`accounting/basket/${product_type}/product/update/project`, configure);
  return res.data;
}

export async function updateBasketQuantity(basket_product_id, basket_id, server_product_id, new_quantity, product_type) {
  let configure = {
    basket_product_id: basket_product_id,
    basket_id: basket_id,
    product_id: server_product_id,
    new_quantity: new_quantity
  };
  const res = await api.put(`accounting/basket/${product_type}/product/update/quantity`, configure);
  return res.data;
}

export async function postGarantiHtml(values) {
  const res = await api.post(`accounting/get/garanti/html`, values);
  return res.data;
}

export async function postPaypalHtml(value) {
  const res = await api.post(`accounting/get/paypal/html`, value);
  return res.data;
}


export async function postPayBasket(values, payment_method, currencyType, isPartial) {
  let currency = "";
  if (currencyType === "₺") {
    currency = "TL";
  } else if (currencyType === "$") {
    currency = "USD";
  } else if (currencyType === "€") {
    currency = "EURO";

  } else {
    currency = "TL";

  }
  const res = await api.post(`accounting/pay/basket?payment_method=${payment_method}&currency_code=${currency}&is_partial=${isPartial}`, values);
  return res.data;
}

export async function paymentSuccessPaypal(info) {
  const res = await api.post(`accounting/payment/success/paypal`, { content: info });
  return await res.data;
}

export async function getBillingList() {
  const res = await api.get(`billing/info/get`);
  return await combineIDContent(res.data);
}

export async function getBillingWithId(id) {
  const res = await api.get(`billing/info/get?id_=${id}`);
  return await combineIDContent(res.data);
}

export async function postCreateBilling(value) {
  const res = await api.post(`billing/info/create`, value);
  return res.data;
}

export async function getProjectList() {
  const res = await api.get(`project/get`);
  return await combineIDContent(res.data);
}

export async function getCurrencyRates() {
  const res = await axios.get(`${frontpageUrl}cache/frontpage/currency/rates/get`);
  const data = res.data;
  return data;
}

//Önceden oluşturduğu bir sepeti ödemediyse yeni sepet oluşturamaması için kontrol.
export async function checkAddBasket() {
  const res = await api.get(`accounting/get/can/create/basket`);
  return res.data;
}

export async function getBasketNote() {
  const res = await api.get(`accounting/basket/note/get`);
  return res.data;
}

export async function updateBasketNote(note) {
  const res = await api.put(`accounting/basket/note/update`, note);
  return res.data;
}

export async function getBasketProductNote(basket_id, basket_product_id, server_product_id, type) {
  const res = await api.get(`accounting/basket/${type}/product/note/get?basket_id=${basket_id}&basket_product_id=${basket_product_id}&${type.replaceAll("-", "_")}_product_id=${server_product_id}`);
  return res.data;
}

export async function updateBasketProductNote(value, type) {
  const res = await api.put(`accounting/basket/${type}/product/note/update`, value);
  return res.data;
}


export async function getAvailablePeriods(product_id, group_id) { //ürünlerin hangi tarihleri kullanabildiğini gösteren api
  const res = await api.get(`${frontpageUrl}cache/frontpage/product/available/periods/by/product/get?product_id=${product_id}&group_id=${group_id}`);
  return combineIDContent(res.data);
}


//Auth İşlemleri

export async function checkLoginMail(email) {

  const res = await axios.post(`${frontpageUrl}check/mail`, { email: email });
  return res.data;
}

export async function beforeLogin(data) {

  const res = await axios.post(`${frontpageUrl}before/login`, data);
  return res.data;
}

export async function beforeLoginCheck(data) {
  const res = await api.post(`${frontpageUrl}check/before/login`, data);
  return res.data;
}

export async function login(data) {

  const res = await axios.post(`${frontpageUrl}login`, qs.stringify(data), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
  api.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
  return res.data;
}

export async function logout() {
  const res = await api.get(`auth/logout`);
  return res.data;
}

export async function personalSendMailValidateCode(email) {
  const res = await axios.post(`${frontpageUrl}register/send/mail/validate/code?email=${email}`);
  return res.data;
}

export async function personalSendPhoneValidateCode(code, phone) {
  const res = await axios.post(`${frontpageUrl}register/send/phone/number/validate/code?phone_number=${phone}&country_code=${code}`);
  return res.data;
}


export async function personalCheckMailValidateCode(email, code) {
  const res = await axios.post(`${frontpageUrl}register/check/mail/validate/code?email=${email}&code=${code}`);
  return res.data;
}

export async function personalCheckPhoneValidateCode(country_code, phone_number, code) {
  const res = await axios.post(`${frontpageUrl}register/check/phone/number/validate/code?phone_number=${phone_number}&country_code=${country_code}&code=${code}`);
  return res.data;
}


export async function registerPersonal(datas) {
  const res = await axios.post(`${frontpageUrl}register/personal`, { content: datas });
  return res.data;
}

export async function registerCorporate(datas) {
  const res = await axios.post(`${frontpageUrl}register/corporate`, { content: datas });
  return res.data;
}

export async function corporateCheckAuthCode(code) {
  const res = await axios.post(`${frontpageUrl}register/corporate/check/auth_code?code=${code}`);
  return res.data;
}

export async function addCorporateFile(file_title, file) {
  const res = await api.post(`add/file?file_titles=${file_title}`, file);
  return res.data;
}

export async function registerPasswordCheck(data) {
  const res = await axios.post(`${frontpageUrl}register/password/check`, data);
  return res.data;
}

export async function forgotPasswordSendCode(email, code, phone, type) {
  const res = await api.post(`${frontpageUrl}forgot/password/send/code?email=${email}&code=${code}&phone=${phone}&sent_code_via=${type}`);
  return res.data;
}

export async function forgotPasswordCheckCode(email, code, pass1, pass2) {
  const res = await api.post(`${frontpageUrl}forgot/password/check/code?email=${email}&code=${code}&new_password_1=${pass1}&new_password_2=${pass2}`);
  return res.data;
}


export async function getRedirectToken() {
  const res = await api.get(`auth/get/redirect/token`);
  return res.data;
}

// live chat //
export async function createLiveChat(data) {
  const res = await axios.post(`${process.env.NEXT_PUBLIC_CHAT_URL}/create/chat`, data);
  return res.data;
}

export async function checkVisitorInfo(data) {
  const res = await axios.post(`${process.env.NEXT_PUBLIC_CHAT_URL}/check/visitor/info`, data);
  return res.data;
}

export async function sendMessage(data, fernetkey, full_path) {
  // const res = await axios.post(`http://ws-chats-admin.makdos.com/post/chat/send?token=${fernetkey}`, data);
  const res = await axios.post(`${process.env.NEXT_PUBLIC_CHAT_URL}/chat/send?visitor_token=${fernetkey}&full_path=${full_path ? full_path : ""}`, data);
}

export async function closeWebSocket(fernetkey) {
  // const res = await axios.delete(`http://ws-chats-admin.makdos.com/del/chat/closed?token=${fernetkey}`);
  const res = await axios.delete(`${process.env.NEXT_PUBLIC_CHAT_URL}/chat/closed${fernetkey ? `?visitor_token=${fernetkey}` : ""}`);
}


//offers

export async function getOffer(offer_code) {
  const res = await axios.get(`${frontpageUrl}get/offer?offer_code=${offer_code}`);
  return res.data;
}

export async function rewiewOffer(offer_code, params) {
  const res = await axios.post(`${frontpageUrl}review/offer?offer_code=${offer_code}`, { content: params });
  return res.data;
}

export async function paymentNotificationOffer(offer_code, params) {
  const res = await axios.post(`${frontpageUrl}payment/notification/offer?offer_code=${offer_code}`, { content: params });
  return res.data;
}

export async function updatePaymentInfoOffer(offer_code, params) {
  const res = await axios.post(`${frontpageUrl}update/payment/info/offer?offer_code=${offer_code}`, { content: params });
  return res.data;
}

export async function approveOffer(offer_code) {
  const res = await axios.post(`${frontpageUrl}approve/offer?offer_code=${offer_code}`);
  return res.data;
}


//Contact

export async function sendMail(params) {
  const res = await axios.post(`${frontpageUrl}form/submit`, { content: params });
  return res.data;
}
